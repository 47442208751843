<template>
    <v-form ref="form" :value="isValid" @input="(e) => { $emit('update:is-valid', e) }">
        <v-text-field
            type="text"
            :value="userName"
            label="User Name"
            prepend-icon="mdi-account"
            :rules="[rules.alphanumeric]"
            :error-messages="errorMessage"
            @keyup.enter="$emit('enter')"
            @input="(e) => { $emit('update:user-name', e) }"
        />
        <v-text-field
            :type="showPassword ? 'text' : 'password'"
            :value="password"
            label="Password"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showPassword = !showPassword"
            :rules="[rules.required]"
            @keyup.enter="$emit('enter')"
            @input="(e) => { $emit('update:password', e) }"
        />
    </v-form>
</template>
<script>
import rules from '@/lib/input-rules'
export default {
    data: () => ({
        rules,
        showPassword: false,
        errorMessage: '',
    }),
    props: {
        userName: { type: String, default: '' },
        password: { type: String, default: '' },
        isValid: { type: Boolean, default: false },
    },
    methods: {
        showUserExistsErrorMessage() {
            this.errorMessage = 'This user name already exists';
            setTimeout(() => { this.errorMessage = ''; }, 2000);
        }
    }
}
</script>