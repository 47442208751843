var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-overlay",
        { attrs: { color: "black", opacity: 0.3, absolute: "" } },
        [
          _c("v-progress-circular", {
            attrs: { color: "white", indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
      typeof _vm.usersExist !== "undefined"
        ? _c("div", [
            _vm.usersExist
              ? _c(
                  "div",
                  [_c("dialog-sign-in", { attrs: { client: _vm.client } })],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("dialog-initial-sign-up", {
                      attrs: { client: _vm.client },
                    }),
                  ],
                  1
                ),
          ])
        : _vm._e(),
      _c("tutti-snackbar", { ref: "snackbar", attrs: { timeout: 5000 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }