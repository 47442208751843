<template>
    <tutti-dialog
        ref="dialog"
        max-width="400"
        :buttons="[
            {
                label: 'Cancel',
                attrs: {
                    color: 'grey darken-1',
                    text: true,
                },
                on: {
                    click() { $refs.dialog.hide(); }
                }
            },
            {
                label: 'Sign up',
                attrs: {
                    color: 'indigo darken-1',
                    text: true,
                    disabled: !isValid,
                },
                on: {
                    click: signUp
                }
            },
        ]" >
        <template #title>
            Sign Up New Account
        </template>
        <template v-slot:body>
            <v-card-text>
                <account-form
                    ref="accountForm"
                    :user-name.sync="userName"
                    :password.sync="password"
                    :is-valid.sync="isValid"
                    @enter="signUp"
                />
            </v-card-text>
        </template>
    </tutti-dialog>
</template>
<script>
import TuttiDialog from '@/components/ui/TuttiDialog'
import AccountForm from './ui/AccountForm'

export default {
    components: {
        TuttiDialog,
        AccountForm
    },
    data: () => ({
        isValid: false,
        userName: '',
        password: '',
    }),
    props: ['client'],
    methods: {
        async signUp() {
            try {
                await this.client.resource.signUp({ user_name: this.userName, password: this.password });
                this.$root.$emit('tutti-snackbar', 'success', `Successfully signed up a new account`);
                this.$refs.dialog.hide();
            } catch(e) {
                if(e.code===this.client.ERROR.ACCOUNT.EXST_USER){
                    this.$refs.accountForm.showUserExistsErrorMessage();
                }
            }
        },
        show() {
            this.$refs.dialog.show();
        },
        onEnter() {
            if(this.isValid) this.signUp();
        }
    }
}
</script>
