<template>
    <v-app v-if="isSignedIn===true">
        <app-bar
            :client="client"
            :selectable-project="!projectSelectorDisabled"
            @change-language="changeLanguage"
            />
        <menu-drawer />

            <router-view app
                v-if="client"
                :client="client"
                :projectName="projectName"
            ></router-view>
        <tutti-snackbar ref="snackbar" :timeout="5000" />
    </v-app>
    <v-app v-else-if="isSignedIn===false">
        <auth-view
            :client="client"
            :usersExist="usersExist"
        />
        <tutti-snackbar ref="snackbar" :timeout="5000" />
    </v-app>
    <v-app v-else>
        <tutti-snackbar ref="snackbar" :timeout="5000" />
    </v-app>
</template>

<script>
import { TuttiClient } from '@iflb/tutti-client'
import AppBar from '@/components/views/ConsoleAppBar'
import MenuDrawer from '@/components/views/ConsoleMenuDrawer'
import AuthView from '@/components/views/ConsoleAuthView'
import TuttiSnackbar from '@/components/ui/TuttiSnackbar'

export default {
    components: { 
        AppBar,
        MenuDrawer,
        AuthView,
        TuttiSnackbar,
    },
    data: () => ({
        client: new TuttiClient(true),
        wsdPath: "/ducts/wsd",
        projectNames: [],
        projectName: null,
        
        isSignedIn: null,
        usersExist: undefined,
    }),
    computed: {
        projectSelectorDisabled() {
            return this.$route.path.startsWith('/console/platform');
        }
    },
    methods: {
        setSignedInStatus(val) { this.isSignedIn = val; },
        setUsersExistStatus(val) { this.usersExist = val; },
        showErrorAlert(type, error) {
            if(type==='sign-up') {
                this.$refs.snackbar.show('error', 'Error in signing up: ${error}');
            }
            else if(type==='sign-in') {
                if(error.code===this.client.ERROR.ACCOUNT.USER_NOT_FOUND) {
                    this.$refs.snackbar.show('error', `Error in signing in: Invalid user name or password`);
                } else {
                    this.$refs.snackbar.show('error', `Error in signing in: ${error}`);
                }
            }
        },

        setComponentHandlers() {
            this.$root.$on('reconnect-failure', () => { this.openClient(); });
            this.$root.$on('select-sign-out', () => { this.signOut(); });
            this.$root.$on('sign-in-success', () => { this.setSignedInStatus(true); });
            this.$root.$on('sign-in-error', (e) => { this.showErrorAlert('sign-in', e); });
            this.$root.$on('sign-up-success', () => { this.setUsersExistStatus(true); });
            this.$root.$on('sign-up-error', (e) => { this.showErrorAlert('sign-up', e); });
            this.$root.$on('project-info-update', (projectNames, projectName) => {
                this.projectNames = projectNames;
                this.projectName = projectName;
                if(projectName){ window.localStorage.setItem("tuttiProject", projectName); }
            });
        },

        openClient() {
            this.client.open(this.wsdPath);
        },
        async signIn() {
            const accessToken = window.localStorage.getItem('tuttiConsoleAccessToken') || '';
            const isSignedIn = (await this.client.resource.signIn({ access_token: accessToken }))!==null;
            this.setSignedInStatus(accessToken && isSignedIn);
        },
        async signOut() {
            await this.client.resource.signOut();
            window.localStorage.removeItem('tuttiConsoleAccessToken');
            this.setSignedInStatus(false);
        },
        changeLanguage(lang) {
            window.localStorage.setItem('tuttiLangForConsole', lang);
            window.location.reload();
        }
    },
    mounted() {
        this.openClient();
        this.setComponentHandlers();

        this.client.invokeOnOpen(async () => {
            try {
                this.setUsersExistStatus( (await this.client.resource.getUserIds()).length > 0 );
                await this.signIn();
            } catch (e) {
                window.localStorage.removeItem('tuttiConsoleAccessToken');
                this.setSignedInStatus(false);
                if(e.code === this.client.ERROR.AUTHENTICATION.INVALID_ACCESS_TOKEN) {
                    this.$refs.snackbar.show('warning', 'Sign-in session expired. Please sign in again.');
                } else {
                    this.$refs.snackbar.show('error', 'Invalid session was found. Please sign in again.');
                }
            }
        });

        this.$root.$on('tutti-snackbar', this.$refs.snackbar.show);
    }
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease-in;
}

.fade-enter-active {
  transition-duration: 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.vjs-tree {
    font-size: 10px;
}
</style>
