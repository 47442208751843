var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      attrs: {
        color: "indigo",
        dark: "",
        app: "",
        dense: "",
        "clipped-right": "",
      },
    },
    [
      _c("v-app-bar-nav-icon", { on: { click: _vm.sendDrawerIconClick } }),
      _c("v-toolbar-title", [
        _vm._v(" " + _vm._s(_vm.$t("console.appBar.title")) + " "),
        _c("span", { staticClass: "text-body-1" }, [
          _vm._v("v" + _vm._s(_vm.currentVersion)),
        ]),
      ]),
      _c("v-spacer"),
      _c("v-autocomplete", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.selectableProject,
            expression: "selectableProject",
          },
        ],
        attrs: {
          "hide-details": "",
          "cache-items": "",
          "solo-inverted": "",
          "hide-no-data": "",
          dense: "",
          rounded: "",
          items: _vm.projectNames,
          label: "Select existing project",
        },
        model: {
          value: _vm.projectName,
          callback: function ($$v) {
            _vm.projectName = $$v
          },
          expression: "projectName",
        },
      }),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.selectableProject,
                              expression: "selectableProject",
                            },
                          ],
                          attrs: { fab: "", dark: "", small: "", icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$refs.dialogCreateProject.show()
                            },
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-plus")])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("console.appBar.createProject")))])]
      ),
      _c("v-spacer"),
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticStyle: { "text-transform": "none" },
                          attrs: { text: "" },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v("mdi-account"),
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.client.accountInfo.userName) + " "
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _c(
                "v-list-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$refs.dialogChangePassword.show()
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-key-variant")])],
                    1
                  ),
                  _c("v-list-item-title", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("console.appBar.accountMenu.changePassword")
                      )
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$root.$emit("select-sign-out")
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-logout")])],
                    1
                  ),
                  _c("v-list-item-title", [
                    _vm._v(
                      _vm._s(_vm.$t("console.appBar.accountMenu.signOut"))
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$refs.dialogSignUp.show()
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-account-plus")])],
                    1
                  ),
                  _c("v-list-item-title", [
                    _vm._v(
                      _vm._s(_vm.$t("console.appBar.accountMenu.createAccount"))
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$refs.dialogDeleteAccount.show()
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c("v-icon", { attrs: { color: "error" } }, [
                        _vm._v("mdi-account-off"),
                      ]),
                    ],
                    1
                  ),
                  _c("v-list-item-title", { staticClass: "error--text" }, [
                    _vm._v(
                      _vm._s(_vm.$t("console.appBar.accountMenu.deleteAccount"))
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("server-status-menu-button", { attrs: { client: _vm.client } }),
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b({ attrs: { icon: "" } }, "v-btn", attrs, false),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-translate")])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _c(
                "v-list-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("change-language", "ja")
                    },
                  },
                },
                [_c("v-list-item-title", [_vm._v("日本語")])],
                1
              ),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("change-language", "en")
                    },
                  },
                },
                [_c("v-list-item-title", [_vm._v("English")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("dialog-create-project", {
        ref: "dialogCreateProject",
        attrs: { client: _vm.client },
        on: { complete: _vm.refreshProjects },
      }),
      _c("dialog-sign-up", {
        ref: "dialogSignUp",
        attrs: { client: _vm.client },
      }),
      _c("dialog-change-password", {
        ref: "dialogChangePassword",
        attrs: { client: _vm.client },
      }),
      _c("tutti-dialog-alert", {
        ref: "dialogDeleteAccount",
        on: { confirm: _vm.deleteAccount },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("v-icon", { attrs: { left: "", color: "error" } }, [
                  _vm._v("mdi-alert"),
                ]),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("console.appBar.deleteAccountDialog.title")) +
                    " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("v-card-text", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("console.appBar.deleteAccountDialog.text", {
                        userName: _vm.client.accountInfo.userName,
                      })
                    ),
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }