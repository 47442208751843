<template>
    <v-menu offset-y v-if="profile">
        <template v-slot:activator="{ on: onMenu, attrs }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: onTooltip }">
                    <v-btn
                        fab
                        small
                        elevation="0"
                        :color="profile[status].btn.color"
                        v-bind="attrs"
                        v-on="{ ...onMenu, ...onTooltip }">
                        <v-icon>{{ profile[status].btn.icon }}</v-icon>
                        <v-badge v-if="false" :color="profile[status].btn.color">
                        </v-badge>
                    </v-btn>
                </template>
                <div style="text-align:center;">
                    <span v-if="status == 'connected'">
                        <v-icon small class="mr-1">mdi-check-circle</v-icon>{{ $t('console.appBar.backendConnectionMenu.connected') }}<br>
                    </span>
                    <span v-else-if="status == 'disconnected'">
                        <v-icon small class="mr-1">mdi-cancel-circle</v-icon>{{ $t('console.appBar.backendConnectionMenu.connected') }}<br>
                        ({{ $t('console.appBar.backendConnectionMenu.autoRetry') }}: {{ retry.maxCnt-retry.cnt }})
                    </span>
                </div>
            </v-tooltip>
        </template>
        <v-list>
            <v-list-item
                v-for="(menu, index) in profile[status].btn.menu"
                :key="index"
                @click="menu.handler()">
                <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
export default {
    data() {
        return {
            status: "connecting",
            retry: {
                enabled: true,
                cnt: 0,
                maxCnt: 5,
                interval: null
            },
            profile: {
                connected: {
                    btn: {
                        color: "success",
                        icon: 'mdi-check-network-outline',
                        menu: [ { title: this.$t('console.appBar.backendConnectionMenu.title.connected'), handler: this.disconnect } ]
                    }
                },
                connecting: {
                    btn: {
                        color: "warning",
                        icon: 'mdi-network-outline',
                    }
                },
                disconnected: {
                    btn: {
                        color: "error",
                        icon: 'mdi-close-network-outline',
                        menu: [ { title: this.$t('console.appBar.backendConnectionMenu.title.disconnected'), handler: this.reconnect } ]
                    }
                }
            }
        }
    },
    props: ['client'],
    methods: {
        reconnect() {
            this.client.reconnect().then(() => {
                if(this.client){
                    this.retry.enabled = true;
                    this.status = "connected";
                    this.retry.cnt = 0;
                } else {
                    if(++this.retry.cnt>=this.retry.maxCnt) {
                        console.error("failed reconnection 5 times");
                        clearInterval(this.retry.interval);
                        this.retry.interval = null;
                    }
                }
            }).catch(() => {
                if(++this.retry.cnt>=this.retry.maxCnt) {
                    console.error("failed reconnection 5 times.");
                    clearInterval(this.retry.interval);
                    this.retry.interval = null;
                }
                this.$root.$emit('reconnect-failure');
            });
        },
        disconnect() {
            this.retry.enabled = false;
            this.client.close();
        }
    },
    created() {

        this.retry.interval = setInterval(() => {
            if(this.status=="disconnected" && this.retry.enabled) { this.reconnect(); }
        }, 3000);


        this.client.connection.on(["onclose", "onerror"], () => { this.status = "disconnected"; } );

        this.client.invokeOnOpen(() => {
            this.status = "connected"
        });
    }
}
</script>
