<template>
    <div>
        <v-overlay color="black" :opacity="0.3" absolute>
            <v-progress-circular color="white" indeterminate size="64" />
        </v-overlay>
        <div v-if="typeof(usersExist)!=='undefined'">
            <div v-if="usersExist">
                <dialog-sign-in
                    :client="client"
                />
            </div>
            <div v-else>
                <dialog-initial-sign-up
                    :client="client"
                />
            </div>
        </div>
        <tutti-snackbar ref="snackbar" :timeout="5000" />
    </div>
</template>
<script>
import DialogInitialSignUp from '@/components/views/ConsoleDialogInitialSignUp'
import DialogSignIn from '@/components/views/ConsoleDialogSignIn'
import TuttiSnackbar from '@/components/ui/TuttiSnackbar'

export default {
    components: { 
        TuttiSnackbar,
        DialogInitialSignUp,
        DialogSignIn,
    },
    props: ['client', 'usersExist'],
}
</script>
