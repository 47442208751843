<template>
    <v-dialog
        persistent
        no-click-animation
        :value="true"
        max-width="700"
    >
        <v-card>
            <v-card-title><v-icon color="indigo" class="mr-2">mdi-lock</v-icon>Sign In</v-card-title>
            <v-card-text>
                <account-form
                    :user-name.sync="userName"
                    :password.sync="password"
                    :is-valid.sync="isValid"
                    @enter="submit"
                />
            </v-card-text>

            <tutti-dialog-actions
                :actions="[
                    {
                        label: 'sign in',
                        color: 'indigo darken-2',
                        callback: submit,
                        disabled: !isValid
                    }
                ]"
            />
        </v-card>
    </v-dialog>
</template>
<script>
import TuttiDialogActions from '@/components/ui/TuttiDialogActions'
import AccountForm from './ui/AccountForm'

export default {
    components: {
        TuttiDialogActions,
        AccountForm
    },
    data() {
        return {
            userName: '',
            password: '',
            isValid: false,
        }
    },
    props: ['client'],
    methods: {
        async submit() {
            if(this.isValid) {
                try {
                    const { access_token: accessToken } = await this.client.resource.signIn({
                            user_name: this.userName,
                            password: this.password,
                        });
                    window.localStorage.setItem('tuttiConsoleAccessToken', accessToken);
                    this.$root.$emit('sign-in-success');
                } catch (e) {
                    this.$root.$emit('sign-in-error', e);
                }
            }
        }
    },
}
</script>
