export const getListItems = (vm) => ([
    [
        null,
        [
            {
                to: '/console/dashboard',
                icon: 'mdi-view-dashboard',
                title: vm.$t('console.menuDrawer.items.dashboard')
            }
        ]
    ],
    [
        vm.$t('console.menuDrawer.groups.designTest'),
        [
            {
                to: '/console/template/',
                icon: 'mdi-application-outline',
                title: vm.$t('console.menuDrawer.items.templates')
            },
            {
                to: '/console/flow',
                icon: 'mdi-wrench',
                title: vm.$t('console.menuDrawer.items.scheme')
            },
            {
                to: '/console/storage',
                icon: 'mdi-database',
                title: vm.$t('console.menuDrawer.items.storage')
            }
        ]
    ],
    [
        vm.$t('console.menuDrawer.groups.publishCollect'),
        [
            {
                to: '/console/response',
                icon: 'mdi-comment-check-outline',
                title: vm.$t('console.menuDrawer.items.responses')
            },
            [
                {
                    icon: 'mdi-account-group',
                    title: vm.$t('console.menuDrawer.items.workerPlatforms')
                },
                [
                    //{
                    //    to: '/console/platform/tutti-market/',
                    //    icon: 'mdi-music',
                    //    title: 'Tutti Market'
                    //},
                    {
                        to: '/console/platform/mturk/',
                        icon: 'mdi-aws',
                        title: vm.$t('console.menuDrawer.items.workerPlatformMTurk')
                    }
                ]
            ]
        ],
    ],
    [
        vm.$t('console.menuDrawer.groups.others'),
        [
            {
                to: '/console/version-log',
                icon: 'mdi-tag-text-outline',
                title: vm.$t('console.menuDrawer.items.versionLogs')
            },
            {
                href: 'https://iflb.github.io/tutti/',
                target: '_blank',
                icon: 'mdi-file-document-outline',
                title: vm.$t('console.menuDrawer.items.documentation')
            }
        ]
    ],
]);