export const logs = [
    {
        version: '0.3.43',
        date: '2023/1/6 19:00',
        messages: {
            feature: [
                `Exported CSV responses can include all (leaf) columns`,
                `Exported CSV responses now convert datetime numbers into a human-friendly format`,
                `In the Responses page, answer columns have all of their leaf members in the list`,
                `In the Responses page, answer columns can be sorted by dragging chips`,
            ],
            bugfix: [
                `Fixed errors that responses CSV was not exported correctly in some columns`,
            ],
        }
    },
    {
        version: '0.3.42',
        date: '2022/12/15 9:40',
        messages: {
            bugfix: [
                `Fixed buggy loop in the flow`,
            ],
        }
    },
    {
        version: '0.3.41',
        date: '2022/12/8 19:05',
        messages: {
            feature: [
                `Prettier flow chart in the Scheme page`,
            ],
        }
    },
    {
        version: '0.3.40',
        date: '2022/12/8 17:40',
        messages: {
            feature: [
                `Added Capture Photo/Video presets`,
            ],
            bugfix: [
                `Static files are now served in production mode`,
                `Fixed a bug in rebuild checking in the Dashboard page`,
                `The Scheme page error log is now displayed correctly`,
                `Fixed a bug that MTurk HITs list page is not displayed correctly`,
            ],
        }
    },
    {
        version: '0.3.39',
        date: '2022/11/16 10:40',
        messages: {
            feature: [
                `Added a Capture Audio preset`,
            ],
        }
    },
    {
        version: '0.3.38',
        date: '2022/11/10 22:20',
        messages: {
            feature: [
                `Fixed an error in creating a template from the blank preset`,
                `Slightly fixed workplace appearance`,
            ],
        }
    },
    {
        version: '0.3.37',
        date: '2022/11/2 14:10',
        messages: {
            feature: [
                `Added project copy / delete buttons in the Dashboard page`,
                `Added parameter-set-relevant backend handlers for delete operations`,
            ],
        }
    },
    {
        version: '0.3.36',
        date: '2022/9/11 13:30',
        messages: {
            feature: [
                `Enabled platform internationalization; added Japanese`,
            ],
        }
    },
    {
        version: '0.3.35',
        date: '2022/8/30 0:35',
        messages: {
            feature: [
                `nano.ans stores file upload paths on template submit`,
                `File Upload template preset is now available`,
            ],
        }
    },
    {
        version: '0.3.34',
        date: '2022/8/27 23:45',
        messages: {
            feature: [
                `Files can be uploaded to file storage from Templates`,
                `Rename/delete icons next to the file name in the file storage`
            ],
        }
    },
    {
        version: '0.3.33',
        date: '2022/8/18 13:55',
        messages: {
            feature: [
                `Updated Audio Segmentation template preset`
            ],
            bugfix: [
                `Fixed some critical project creation error glitches`,
            ]
        }
    },
    {
        version: '0.3.32',
        date: '2022/8/15 14:15',
        messages: {
            feature: [
                `Improved appearance of the version log`
            ],
            bugfix: [
                `Fixed some critical build error glitches`,
            ]
        }
    },
    {
        version: '0.3.31',
        date: '2022/8/15 15:25',
        messages: {
            feature: [
                `Added some "Edit" menu in Code Editor`
            ]
        }
    },
    {
        version: '0.3.30',
        date: '2022/8/10 21:15',
        messages: {
            feature: [
                `Code editor now has a file explorer and tabs`
            ],
            bugfix: [
                `Fixed an issue that MTurk Post HITs failed then IntegerValues are set for a Qualification`
            ]
        }
    },
    {
        version: '0.3.29',
        date: '2022/7/28 21:05',
        messages: {
            feature: [
                `Some improvements for Tutti CLI commands`,
                `Code editor now checks if the file is saved`,
                `Template toolbar now displays # of loaded templates`,
                `Simpler visualization for scheme flow`
            ],
        }
    },
    {
        version: '0.3.28',
        date: '2022/6/21 18:15',
        messages: {
            feature: [
                `Added some features for Audio Segmentation template preset<br>
                    <ul>
                        <li>Improved waveform visualization</li>
                        <li>Improved interface for changing segment time</li>
                        <li>Drag-and-move timeline</li>
                        <li>Edit label segments by hitting Enter key</li>
                    </ul>
                `
            ]
        }
    },
    {
        version: '0.3.27',
        date: '2022/6/20 15:35',
        messages: {
            bugfix: [
                `Fixed an issue that mounted hook of the first template double-called`,
            ],
        },
    },
    {
        version: '0.3.26',
        date: '2022/6/16 0:40',
        messages: {
            feature: [
                `Added Audio Segmentation template preset`,
            ],
        },
    },
    {
        version: '0.3.25',
        date: '2022/6/13 18:40',
        messages: {
            feature: [
                `Response table now displays platform name and platform worker ID`,
            ],
        },
    },
    {
        version: '0.3.24',
        date: '2022/6/13 18:10',
        messages: {
            feature: [
                `Template and template preset renderer shows nano props and nano answers in the right navigation bar`,
            ],
        },
    },
    {
        version: '0.3.23',
        date: '2022/6/13 3:55',
        messages: {
            feature: [
                `Users can change their password`,
            ],
        },
    },
    {
        version: '0.3.22',
        date: '2022/6/12 23:15',
        messages: {
            feature: [
                `File Storage is now available`,
                `Added Video Timeline Segment Labeling preset`,
            ],
        },
    },
    {
        version: '0.3.21',
        date: '2022/6/9 14:30',
        messages: {
            feature: [
                `Creating templates from preset catalog is now available`,
                `Response table can now be grouped by a column`,
                `Large answer value object is skipped and displayed in a dialog`,
            ],
            bugfix: [
                `Fixed data loading issue in response table`,
            ],
        },
    },
    {
        version: '0.3.20',
        date: '2022/5/23 22:35',
        messages: {
            bugfix: [
                `Fixed setting response function in workplace`,
            ],
        },
    },
    {
        version: '0.3.19',
        date: '2022/5/6 18:35',
        messages: {
            feature: [
                `Added account add/delete menus`,
            ],
            bugfix: [
                `Now managing HIT batches individually per credential`,
            ],
        },
    },
    {
        version: '0.3.18',
        date: '2022/3/10 18:10',
        messages: {
            feature: [
                `Response watcher now returns from past records`,
            ],
        },
    },
    {
        version: '0.3.17',
        date: '2022/3/4 0:25',
        messages: {
            feature: [
                `Project scheme editor is now available`,
                `Changed page toolbars for template and scheme pages`,
            ],
        },
    },
    {
        version: '0.3.16',
        date: '2022/3/3 19:15',
        messages: {
            bugfix: [
                `Fixed a problem that ESLint error overlay prevented further edit in template editor`,
            ],
        },
    },
    {
        version: '0.3.15',
        date: '2022/3/3 18:40',
        messages: {
            feature: [
                `Implemented template code editor`,
            ],
        },
    },
    {
        version: '0.3.14',
        date: '2022/3/2 15:20',
        messages: {
            bugfix: [
                `Fixed internal errors in deleting some metadata in deleting nanotasks`,
            ],
        },
    },
    {
        version: '0.3.13',
        date: '2022/3/1 13:50',
        messages: {
            bugfix: [
                `Fixed internal errors in deleting some metadata in deleting nanotasks`,
                `Handling and showing errors in MTurk sign-in`,
            ],
        },
    },
    {
        version: '0.3.12',
        date: '2022/2/28 11:05',
        messages: {
            bugfix: [
                `Fixed internal errors in deleting some metadata for Tutti resources`,
                `Alert dialog in deleting MTurk credentials now displays messages`,
            ],
        },
    },
    {
        version: '0.3.11',
        date: '2022/2/16 14:55',
        messages: {
            feature: [
                `Added some frontend features for Tutti Market's Job Classes`,
            ],
        },
    },
    {
        version: '0.3.10',
        date: '2022/2/16 02:35',
        messages: {
            bugfix: [
                `Backend fix to work session-relevant event`,
                `Frontend fix to Tutti market's job terminator page`,
                `Frontend fix to work place appearance`,
            ],
        },
    },
    {
        version: '0.3.9',
        date: '2022/2/15 23:00',
        messages: {
            feature: [
                `Backend implementations for:
                    <ul>
                        <li>Response watcher methods</li>
                        <li>Automation/Platform parameter sets</li>
                        <li>Nanotask groups</li>
                    </ul>
                `
            ],
        },
    },
    {
        version: '0.3.8',
        date: '2022/2/14 22:10',
        messages: {
            bugfix: [
                `Fixed glitches for MTurk HIT Creation: adding HITs to existing batch & "Copy To New" button`,
            ],
        },
    },
    {
        version: '0.3.7',
        date: '2022/2/14 11:10',
        messages: {
            feature: [
                `Vuetify_Video template preset has been added`,
            ],
            bugfix: [
                `Renewed client API version so signing up does not fail`,
            ],
        },
    },
    {
        version: '0.3.6',
        date: '2022/2/3 23:15',
        messages: {
            feature: [
                `Tooltips are shown for menu bar icons when folded`,
                `Refresh button in MTurk HITs list page`,
                `Responses can be downloaded as files (JSON & CSV; experimental)`,
            ],
            bugfix: [
                `Resolved some input type bugs that prevented from creating MTurk HITs`,
            ]
        },
    },
    {
        version: '0.3.5',
        date: '2022/2/2 0:15',
        messages: {
            feature: [
                `.env now accepts registering allowed CORS origins (mainly for API client accesses)`,
            ],
            bugfix: [
                `TemplateNode works fine in tasks when merged_props parameter is unspecified`,
                `Some old template presets are now working fine`,
            ],
            improvement: [
                `Faster build by caching Docker image data`,
            ]
        },
    },
    {
        version: '0.3.4',
        date: '2022/1/26 15:50',
        messages: {
            bugfix: [
                `Fixed bug that double-counted WorkerContext.project_id counter`,
            ],
        },
    },
    {
        version: '0.3.3',
        date: '2022/1/25 19:30',
        messages: {
            feature: [
                `Created reload button in MTurk's Assignments list page`,
            ],
        },
    },
    {
        version: '0.3.2',
        date: '2022/1/25 17:30',
        messages: {
            bugfix: [
                `MTurk's ApproveAssignments now accepts OverrideRejection parameter`,
            ],
        },
    },
    {
        version: '0.3.1',
        date: '2022/1/25 11:30',
        messages: {
            feature: [
                `getProjectScheme operation now requires authentication`,
            ],
        },
    },
    {
        version: '0.3.0',
        date: '2022/1/15 19:00',
        messages: {
            feature: [
                `Started recording version logs`,
            ],
        },
    },
];

export const currentVersion = logs[0].version;
