<template>
    <tutti-dialog
        ref="dialog"
        max-width="400"
        :buttons="[
            {
                label: 'Cancel',
                attrs: {
                    color: 'grey darken-1',
                    text: true,
                },
                on: {
                    click() { $refs.dialog.hide(); }
                }
            },
            {
                label: 'Create',
                attrs: {
                    color: 'indigo darken-1',
                    text: true,
                    disabled: !valid,
                },
                on: {
                    click: createProject
                }
            },
        ]" >
        <template #title>
            Create New Project
        </template>
        <template v-slot:body>
            <v-card-text>
                <v-form v-model="valid" @submit.prevent>
                    <v-text-field
                        autofocus
                        v-model="newProjectName"
                        filled
                        prepend-icon="mdi-pencil"
                        label="Project Name"
                        @keyup.enter="onEnter"
                        :rules="[(val) => (rules.reg(/^[a-zA-Z0-9-_]+$/, 'Only numbers, alphabets, _, and - are allowed', val))]"
                        :error-messages="errorMessage"
                    />
                </v-form>
            </v-card-text>
        </template>
    </tutti-dialog>
</template>
<script>
import TuttiDialog from '@/components/ui/TuttiDialog'
import rules from '@/lib/input-rules'

export default {
    components: {
        TuttiDialog
    },
    data: () => ({
        rules,
        valid: false,
        errorMessage: '',
        newProjectName: '',
    }),
    props: ['client'],
    methods: {
        async createProject() {
            try {
                const projectName = await this.client.resource.createProject({ project_name: this.newProjectName });
                this.$root.$emit('tutti-snackbar', 'success', `Successfully created project '${projectName}'`);
                this.$refs.dialog.hide();
                this.$emit('complete');
            } catch(e) {
                if(e.code===this.client.ERROR.PROJECT.EXST_PRJ){
                    this.errorMessage = 'This project name already exists';
                    setTimeout(() => { this.errorMessage = ''; }, 2000);
                }
            }
        },
        show() {
            this.$refs.dialog.show();
        },
        onEnter() {
            if(this.valid) this.createProject();
        }
    }
}
</script>
