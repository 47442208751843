var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.profile
    ? _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on: onMenu, attrs }) {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on: onTooltip }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            fab: "",
                                            small: "",
                                            elevation: "0",
                                            color:
                                              _vm.profile[_vm.status].btn.color,
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      { ...onMenu, ...onTooltip }
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.profile[_vm.status].btn.icon
                                          )
                                        ),
                                      ]),
                                      false
                                        ? _c("v-badge", {
                                            attrs: {
                                              color:
                                                _vm.profile[_vm.status].btn
                                                  .color,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c("div", { staticStyle: { "text-align": "center" } }, [
                          _vm.status == "connected"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("mdi-check-circle")]
                                  ),
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "console.appBar.backendConnectionMenu.connected"
                                      )
                                    )
                                  ),
                                  _c("br"),
                                ],
                                1
                              )
                            : _vm.status == "disconnected"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("mdi-cancel-circle")]
                                  ),
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "console.appBar.backendConnectionMenu.connected"
                                      )
                                    )
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " (" +
                                      _vm._s(
                                        _vm.$t(
                                          "console.appBar.backendConnectionMenu.autoRetry"
                                        )
                                      ) +
                                      ": " +
                                      _vm._s(_vm.retry.maxCnt - _vm.retry.cnt) +
                                      ") "
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            941374334
          ),
        },
        [
          _c(
            "v-list",
            _vm._l(_vm.profile[_vm.status].btn.menu, function (menu, index) {
              return _c(
                "v-list-item",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      return menu.handler()
                    },
                  },
                },
                [_c("v-list-item-title", [_vm._v(_vm._s(menu.title))])],
                1
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }