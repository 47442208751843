function makeData(src, evt, data) {
    return { protocol: 'tuttiMessage', src, evt, data };
}

function isTuttiMessage(src, msg) {
    return msg.protocol==='tuttiMessage' && msg.src===src;
}

function setHandler(src, handler) {
    const _handler = (e) => {
        if(isTuttiMessage(src, e.data)) handler(e.data);
    };
    window.addEventListener('message', _handler);
    return _handler;
}

function removeHandler(handler) {
    window.removeEventListener('message', handler);
}

function send(target, src, evt, data) {
    target.postMessage(makeData(src, evt, data));
}

export default {
    setHandler,
    removeHandler,
    send
}
