<template>
    <tutti-dialog
        ref="dialog"
        max-width="400"
        :buttons="[
            {
                label: 'Cancel',
                attrs: {
                    color: 'grey darken-1',
                    text: true,
                },
                on: {
                    click() { $refs.dialog.hide(); }
                }
            },
            {
                label: 'Confirm',
                attrs: {
                    color: 'indigo darken-1',
                    text: true,
                    disabled: !isValid,
                },
                on: {
                    click: changePassword
                }
            },
        ]" >
        <template #title>
            Change Password
        </template>
        <template v-slot:body>
            <v-card-text>
                <password-form
                    ref="passwordForm"
                    :old-password.sync="oldPassword"
                    :new-password1.sync="newPassword1"
                    :new-password2.sync="newPassword2"
                    :is-valid.sync="isValid"
                    @enter="changePassword"
                />
            </v-card-text>
        </template>
    </tutti-dialog>
</template>
<script>
import TuttiDialog from '@/components/ui/TuttiDialog'
import PasswordForm from './ui/PasswordForm'

export default {
    components: {
        TuttiDialog,
        PasswordForm
    },
    data: () => ({
        isValid: false,
        oldPassword: '',
        newPassword1: '',
        newPassword2: '',
    }),
    props: ['client'],
    methods: {
        async changePassword() {
            try {
                await this.client.resource.changeAccountPassword({
                    user_name: this.client.accountInfo.userName,
                    old_password: this.oldPassword,
                    new_password: this.newPassword1
                });
                this.$root.$emit('tutti-snackbar', 'success', `Successfully changed password`);
                this.$refs.dialog.hide();
            } catch(e) {
                if(e.code===this.client.ERROR.ACCOUNT.USER_NOT_FOUND){
                    this.$refs.passwordForm.showIncorrectOldPasswordErrorMessage();
                } else {
                    console.error(e);
                }
            }
        },
        show() {
            this.$refs.dialog.show();
        }
    }
}
</script>
