var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tutti-dialog", {
    ref: "dialog",
    attrs: {
      "max-width": "400",
      buttons: [
        {
          label: "Cancel",
          attrs: {
            color: "grey darken-1",
            text: true,
          },
          on: {
            click() {
              _vm.$refs.dialog.hide()
            },
          },
        },
        {
          label: "Create",
          attrs: {
            color: "indigo darken-1",
            text: true,
            disabled: !_vm.valid,
          },
          on: {
            click: _vm.createProject,
          },
        },
      ],
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" Create New Project ")]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "v-card-text",
              [
                _c(
                  "v-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                    model: {
                      value: _vm.valid,
                      callback: function ($$v) {
                        _vm.valid = $$v
                      },
                      expression: "valid",
                    },
                  },
                  [
                    _c("v-text-field", {
                      attrs: {
                        autofocus: "",
                        filled: "",
                        "prepend-icon": "mdi-pencil",
                        label: "Project Name",
                        rules: [
                          (val) =>
                            _vm.rules.reg(
                              /^[a-zA-Z0-9-_]+$/,
                              "Only numbers, alphabets, _, and - are allowed",
                              val
                            ),
                        ],
                        "error-messages": _vm.errorMessage,
                      },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.onEnter.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.newProjectName,
                        callback: function ($$v) {
                          _vm.newProjectName = $$v
                        },
                        expression: "newProjectName",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }