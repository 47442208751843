<template>
    <v-dialog
        persistent
        no-click-animation
        :value="true"
        max-width="700"
    >
        <v-card>
            <v-card-title><v-icon color="warning" class="mr-2">mdi-alert</v-icon>Set up admin account</v-card-title>
            <v-card-text>
                Your Tutti environment does not have any account to log in with.<br>
                Please register your first (<b>admin</b>) account below:
                <account-form
                    :user-name.sync="userName"
                    :password.sync="password"
                    :is-valid.sync="isValid"
                    @enter="submit"
                />
            </v-card-text>

            <tutti-dialog-actions
                :actions="[
                    {
                        label: 'confirm',
                        color: 'indigo darken-2',
                        callback: submit,
                        disabled: !isValid
                    }
                ]"
            />
        </v-card>
    </v-dialog>
</template>
<script>
import TuttiDialogActions from '@/components/ui/TuttiDialogActions'
import AccountForm from './ui/AccountForm'

export default {
    components: {
        TuttiDialogActions,
        AccountForm
    },
    data() {
        return {
            userName: '',
            password: '',
            isValid: false,
        }
    },
    props: ['client'],
    methods: {
        async submit() {
            if(this.isValid) {
                const userId = await this.client.resource.signUp({
                        user_name: this.userName,
                        password: this.password,
                        privilege_ids: []
                    });
                if(userId) this.$root.$emit('sign-up-success', this.userName, this.password);
                else this.$root.$emit('sign-up-error');
            }
        }
    },
}
</script>
