var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { value: _vm.isValid },
      on: {
        input: (e) => {
          _vm.$emit("update:is-valid", e)
        },
      },
    },
    [
      _c("v-text-field", {
        attrs: {
          type: _vm.showOldPassword ? "text" : "password",
          value: _vm.oldPassword,
          label: "Old Password",
          "append-icon": _vm.showOldPassword ? "mdi-eye-off" : "mdi-eye",
          rules: [_vm.rules.required],
          "error-messages": _vm.oldPasswordErrorMessage,
        },
        on: {
          "click:append": function ($event) {
            _vm.showOldPassword = !_vm.showOldPassword
          },
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.$emit("enter")
          },
          input: (e) => {
            _vm.$emit("update:old-password", e)
          },
        },
      }),
      _c("v-text-field", {
        attrs: {
          type: _vm.showNewPassword1 ? "text" : "password",
          value: _vm.newPassword1,
          label: "New Password",
          "append-icon": _vm.showNewPassword1 ? "mdi-eye-off" : "mdi-eye",
          rules: [_vm.rules.required],
          "error-messages": _vm.newPasswordErrorMessage,
        },
        on: {
          "click:append": function ($event) {
            _vm.showNewPassword1 = !_vm.showNewPassword1
          },
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.$emit("enter")
          },
          input: (e) => {
            _vm.$emit("update:new-password1", e)
          },
        },
      }),
      _c("v-text-field", {
        attrs: {
          type: _vm.showNewPassword2 ? "text" : "password",
          value: _vm.newPassword2,
          label: "New Password",
          "append-icon": _vm.showNewPassword2 ? "mdi-eye-off" : "mdi-eye",
          rules: [
            _vm.rules.required,
            () =>
              _vm.newPassword1 === _vm.newPassword2 ||
              "New passwords do not match",
          ],
          "error-messages": _vm.newPasswordErrorMessage,
        },
        on: {
          "click:append": function ($event) {
            _vm.showNewPassword2 = !_vm.showNewPassword2
          },
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.$emit("enter")
          },
          input: (e) => {
            _vm.$emit("update:new-password2", e)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }