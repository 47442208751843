var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tutti-dialog", {
    ref: "dialog",
    attrs: {
      "max-width": "400",
      buttons: [
        {
          label: "Cancel",
          attrs: {
            color: "grey darken-1",
            text: true,
          },
          on: {
            click() {
              _vm.$refs.dialog.hide()
            },
          },
        },
        {
          label: "Sign up",
          attrs: {
            color: "indigo darken-1",
            text: true,
            disabled: !_vm.isValid,
          },
          on: {
            click: _vm.signUp,
          },
        },
      ],
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" Sign Up New Account ")]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "v-card-text",
              [
                _c("account-form", {
                  ref: "accountForm",
                  attrs: {
                    "user-name": _vm.userName,
                    password: _vm.password,
                    "is-valid": _vm.isValid,
                  },
                  on: {
                    "update:userName": function ($event) {
                      _vm.userName = $event
                    },
                    "update:user-name": function ($event) {
                      _vm.userName = $event
                    },
                    "update:password": function ($event) {
                      _vm.password = $event
                    },
                    "update:isValid": function ($event) {
                      _vm.isValid = $event
                    },
                    "update:is-valid": function ($event) {
                      _vm.isValid = $event
                    },
                    enter: _vm.signUp,
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }