var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      attrs: { app: "", "mini-variant": _vm.miniVariant },
      model: {
        value: _vm.shown,
        callback: function ($$v) {
          _vm.shown = $$v
        },
        expression: "shown",
      },
    },
    [
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _vm._l(_vm.listItems, function (group, i) {
            return _c(
              "v-list-item-group",
              {
                key: `group-${i}`,
                attrs: { "active-class": "indigo--text text--accent-4" },
              },
              [
                group[0] && !_vm.miniVariant
                  ? _c("v-subheader", {
                      domProps: { innerHTML: _vm._s(group[0]) },
                    })
                  : _vm._e(),
                _vm._l(group[1], function (item, j) {
                  return _c(
                    "div",
                    { key: `group-${i}-item-${j}` },
                    [
                      typeof item === "object" && !Array.isArray(item)
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { right: "", disabled: !_vm.miniVariant },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ attrs, on }) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  to: item.to || undefined,
                                                  href: item.href || undefined,
                                                  target:
                                                    item.target || undefined,
                                                },
                                              },
                                              "v-list-item",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            item.icon
                                              ? _c(
                                                  "v-list-item-icon",
                                                  [
                                                    _c("v-icon", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          item.icon
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c("v-list-item-title", {
                                              domProps: {
                                                innerHTML: _vm._s(item.title),
                                              },
                                            }),
                                            item.target
                                              ? _c(
                                                  "v-list-item-action",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("mdi-launch")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v(_vm._s(item.title))])]
                          )
                        : _c(
                            "v-tooltip",
                            {
                              attrs: { right: "", disabled: !_vm.miniVariant },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ attrs, on }) {
                                      return [
                                        _c(
                                          "v-list-group",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "prepend-icon": item[0].icon,
                                                  value: false,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.miniVariant = false
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    item[0]
                                                                      .title
                                                                  ),
                                                              },
                                                            }
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              "v-list-group",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          _vm._l(item[1], function (_item, k) {
                                            return _c(
                                              "v-list-item",
                                              {
                                                key: `group-${i}-item-${j}-${k}`,
                                                staticClass: "pl-6",
                                                attrs: {
                                                  to: _item.to || undefined,
                                                  href: _item.href || undefined,
                                                  target:
                                                    _item.target || undefined,
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-list-item-icon",
                                                  [
                                                    _c("v-icon", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _item.icon
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c("v-list-item-title", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _item.title
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_vm._v(" " + _vm._s(item[0].title) + " ")]
                          ),
                    ],
                    1
                  )
                }),
              ],
              2
            )
          }),
          _c("v-divider"),
          _c(
            "v-list-item",
            {
              on: {
                click: function ($event) {
                  _vm.miniVariant = !_vm.miniVariant
                },
              },
            },
            [
              _c(
                "v-list-item-action",
                [
                  _c("v-icon", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.miniVariant
                          ? "mdi-chevron-right"
                          : "mdi-chevron-left"
                      ),
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("console.menuDrawer.fold"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }