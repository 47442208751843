<template>
    <v-form ref="form" :value="isValid" @input="(e) => { $emit('update:is-valid', e) }">
        <v-text-field
            :type="showOldPassword ? 'text' : 'password'"
            :value="oldPassword"
            label="Old Password"
            :append-icon="showOldPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showOldPassword = !showOldPassword"
            :rules="[rules.required]"
            :error-messages="oldPasswordErrorMessage"
            @keyup.enter="$emit('enter')"
            @input="(e) => { $emit('update:old-password', e) }"
        />
        <v-text-field
            :type="showNewPassword1 ? 'text' : 'password'"
            :value="newPassword1"
            label="New Password"
            :append-icon="showNewPassword1 ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showNewPassword1 = !showNewPassword1"
            :rules="[rules.required]"
            :error-messages="newPasswordErrorMessage"
            @keyup.enter="$emit('enter')"
            @input="(e) => { $emit('update:new-password1', e) }"
        />
        <v-text-field
            :type="showNewPassword2 ? 'text' : 'password'"
            :value="newPassword2"
            label="New Password"
            :append-icon="showNewPassword2 ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showNewPassword2 = !showNewPassword2"
            :rules="[rules.required, () => newPassword1===newPassword2 || 'New passwords do not match']"
            :error-messages="newPasswordErrorMessage"
            @keyup.enter="$emit('enter')"
            @input="(e) => { $emit('update:new-password2', e) }"
        />
    </v-form>
</template>
<script>
import rules from '@/lib/input-rules'
export default {
    data: () => ({
        rules,
        showOldPassword: false,
        showNewPassword1: false,
        showNewPassword2: false,
        oldPasswordErrorMessage: '',
        newPasswordErrorMessage: '',
    }),
    props: {
        oldPassword: { type: String, default: '' },
        newPassword1: { type: String, default: '' },
        newPassword2: { type: String, default: '' },
        isValid: { type: Boolean, default: false },
    },
    methods: {
        showIncorrectOldPasswordErrorMessage() {
            this.oldPasswordErrorMessage = 'Old password is incorrect';
            setTimeout(() => { this.oldPasswordErrorMessage = ''; }, 2000);
        },
    }
}
</script>